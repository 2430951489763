import { TemplateEnum } from "../../types/templateEnum";
import BarChartWithToggle from "./barChartNoPadding";
import { ChartQuestionAnswersResponseType } from "../../types/chartQuestionAnswersInfoType";
import PieChartWithNeedle from "./pieChartWithNeedle";

type ChartComponentProps = {
  data: ChartQuestionAnswersResponseType;
  language: string | undefined;
};

type ChartMappingType = {
  [key in TemplateEnum]?: React.FC<ChartComponentProps>;
};

export const chartsMapping: ChartMappingType = {
  MULTIPLE_CHOICE: BarChartWithToggle,
  SINGLE_CHOICE: BarChartWithToggle,
  LOYALTY_INDEX: BarChartWithToggle,
  NPS: PieChartWithNeedle,
};
