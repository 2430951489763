import React, { FC, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../store";
import { I18n, Translate } from "react-redux-i18n";
import { Button, DatePicker, DatePickerProps, Select } from "antd";
import { useDatesFilter } from "../answersTab/helpers";
import { RangePickerProps } from "antd/es/date-picker";
import { DownloadOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { POST } from "../../../../../../types/httpMethod";
import { requestApi } from "../../../../../../utils/requestApi";
import {
  ChartQuestionAnswersInfoType,
  ChartQuestionAnswersResponseType,
} from "../../../../../../types/chartQuestionAnswersInfoType";
import { Dayjs } from "dayjs";
import { DATE_TIME_FORMAT } from "../../../../../../utils";
import { useActions } from "../../../../../../hooks/useActions";
import StatisticsLayout from "../../../../../../components/statisticsLayout";
import { chartsMapping } from "../../../../../../components/charts/chartsMapping";

const ReportsTab: FC = () => {
  const { id: surveyId } = useParams();
  const survey = useSelector((state: RootState) => state.surveys?.current);
  const [lang, setLang] = useState<string | undefined>();
  const [canApply, setCalApply] = useState(false);
  const [data, setData] = useState<ChartQuestionAnswersResponseType>([]);

  const { selectedDates, setSelectedDates, resetDates } = useDatesFilter();
  const { getSurveyReportPdf } = useActions();

  const onDatesPick = (value: DatePickerProps["value"] | RangePickerProps["value"]) => {
    if (value === null) {
      resetDates();
    } else if (Array.isArray(value)) {
      setSelectedDates(value);
    }
    setCalApply(true);
  };

  const applyFilters = useCallback(() => {
    const obj = {
      ...(!!selectedDates[0] && !!selectedDates[1]
        ? {
            from: (selectedDates[0] as Dayjs).format(DATE_TIME_FORMAT),
            to: (selectedDates[1] as Dayjs).format(DATE_TIME_FORMAT),
          }
        : { from: undefined, to: undefined }),
    };
    if (canApply) {
      loadData(obj?.from, obj?.to);
      setCalApply(false);
    }
  }, [selectedDates, canApply]);

  const handleDownload = useCallback(() => {
    if (surveyId) {
      getSurveyReportPdf(surveyId);
    }
  }, [surveyId]);

  useEffect(() => {
    setLang(survey?.languages?.at(0)?.langCode);
  }, [survey?.languages]);

  const loadData = useCallback(
    (from?: string, to?: string) => {
      requestApi(POST, "/chart/question/answers-info", { from, to }, false, false, {}, { surveyId }).then(setData);
    },
    [surveyId],
  );

  useEffect(() => {
    loadData();
  }, []);

  const renderChart = (item: ChartQuestionAnswersInfoType) => {
    const ChartComponent = chartsMapping[item.templateType];
    if (ChartComponent) {
      return <ChartComponent key={item.questionId} data={[item]} language={lang} />;
    }
    return null;
  };

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center", gap: 8, width: "100%", marginBottom: 10 }}>
        <DatePicker.RangePicker
          showTime={{ format: "HH:mm" }}
          format="YYYY-MM-DD HH:mm"
          onChange={onDatesPick}
          value={selectedDates}
          inputReadOnly
        />
        <Button onClick={applyFilters} type={"primary"} style={{ marginRight: 30 }} disabled={!canApply}>
          <Translate value={"display"} />
        </Button>
        <Select
          value={lang}
          style={{ width: 160 }}
          onChange={(value) => setLang(value)}
          placeholder={I18n.t("selectLanguage")}
        >
          {survey?.languages?.map((lang, index) => (
            <Select.Option key={index} value={lang.langCode}>
              {lang.langName}
            </Select.Option>
          ))}
        </Select>
        <Button type={"default"} icon={<DownloadOutlined />} onClick={handleDownload} style={{ marginLeft: "auto" }}>
          <Translate value={"download"} />
        </Button>
      </div>
      {data.map((item) => {
        const chart = renderChart(item);

        if (item.templateType === "NPS") {
          return (
            <div key={item.questionId} style={{ display: "flex", alignItems: "center" }}>
              <StatisticsLayout title={item.questionName}>{chart}</StatisticsLayout>
            </div>
          );
        } else if (chart) {
          return (
            <StatisticsLayout key={item.questionId} title={item.questionName}>
              {chart}
            </StatisticsLayout>
          );
        } else {
          return null;
        }
      })}
    </div>
  );
};

export default ReportsTab;
