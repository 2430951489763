import React, { FC } from "react";
import { Bar, BarChart, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { ChartQuestionAnswersResponseType } from "../../types/chartQuestionAnswersInfoType";
import { I18n } from "react-redux-i18n";
import removeMarkdown from "remove-markdown";
import he from "he";

const barColors = [
  "#1f77b4",
  "#ff7f0e",
  "#2ca02c",
  "#F0B67F",
  "#FE5F55",
  "#FDCA40",
  "#3772FF",
  "#080708",
  "#DAE0F2",
  "#F9CFF2",
];

type BarChartWithToggleProps = {
  data: ChartQuestionAnswersResponseType;
  language?: string;
};

const BarChartWithToggle: FC<BarChartWithToggleProps> = ({ data, language = "RU" }) => {
  const getTranslatedText = (choices: any[]) => {
    if (!choices || choices.length === 0) return "";

    const translation = choices[0]?.translations?.find((t: any) => t.langCode === language);

    return translation?.text || choices[0]?.translations?.[0]?.text || "";
  };

  const chartDataState = data.flatMap((item) =>
    item.answers
      .map((answer) => {
        const isChoiceTemplate = ["MULTIPLE_CHOICE", "SINGLE_CHOICE"].includes(item.templateType);
        const hasChoices = answer.choices && answer.choices.length > 0;

        if (isChoiceTemplate && !hasChoices) {
          return null;
        }

        const rawText = getTranslatedText(answer.choices) || answer.answerMessage;
        const cleanText = he.decode(removeMarkdown(rawText));

        return {
          total: answer.answerCount,
          name: cleanText,
          percentage: answer.percentage,
        };
      })
      .filter(
        (entry): entry is { total: number; name: string; percentage: number } =>
          entry !== null && Boolean(entry.total) && Boolean(entry.name),
      ),
  );

  const rowHeight = 60;
  const chartHeight = Math.max(chartDataState.length * rowHeight, 100);

  const truncateText = (text: string) => {
    if (text.length > 30) {
      return text.slice(0, 40) + "...";
    }
    return text;
  };

  if (chartDataState.length === 0) {
    return <div className="text-center p-4">{I18n.t("noData")}</div>;
  }

  return (
    <ResponsiveContainer width="95%" height={chartHeight}>
      <BarChart data={chartDataState} layout="vertical">
        <XAxis type="number" stroke="#000000" />
        <YAxis
          dataKey="name"
          type="category"
          stroke="#000000"
          width={150}
          tickFormatter={(value) => truncateText(value)}
        />
        <Tooltip
          wrapperStyle={{
            width: "200px",
            maxWidth: "200px",
          }}
          contentStyle={{
            maxWidth: "200px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "pre-wrap",
            wordBreak: "break-word",
          }}
          formatter={(value, name) => {
            if (name === "total") {
              const entry = chartDataState.find((item) => item.total === value);
              return [`${I18n.t("grade")}: ${value}, ${I18n.t("percentage")}: ${entry?.percentage.toFixed(1)}%`];
            }
            return value;
          }}
        />
        <Bar dataKey="total" fill="#00a0fc" stroke="#000000" strokeWidth={1}>
          {chartDataState.map((_entry, index) => (
            <Cell key={`cell-${index}`} fill={barColors[index % barColors.length]} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarChartWithToggle;
