import { FC, ReactNode } from "react";
import { TEXT_LIGHT_COLOR } from "../utils";

type PageNumber = { current: number; total: number };

type Props = {
  readonly title?: string;
  readonly subTitle?: string;
  readonly children: ReactNode;
  readonly padding?: string;
  readonly page?: PageNumber;
  readonly height?: string;
  readonly width?: string;
};

const StatisticsLayout: FC<Props> = (props) => {
  const { title, subTitle, children, padding, page, height = "100%", width = "100%" } = props;
  return (
    <div style={{ color: "#000000", flex: 1, width }}>
      <h3 style={{ visibility: !!title ? "visible" : "hidden", fontWeight: 600 }}>{title || "-"}</h3>
      <div
        style={{
          borderRadius: 8,
          padding: padding ?? "40px 120px",
          border: "10px solid #F7F7F7",
          width,
          ...(!height ? {} : { height, display: "flex", flexDirection: "column" }),
        }}
      >
        {children}
        {!!page && (
          <div style={{ display: "flex", alignItems: "center", width: "100%", marginTop: "auto", paddingTop: 50 }}>
            <div style={{ marginLeft: "auto" }}>
              <span style={{ fontWeight: 600 }}>{page.current}</span>
              <span style={{ color: TEXT_LIGHT_COLOR }}>/{page.total}</span>
            </div>
          </div>
        )}
      </div>
      <div style={{ display: "flex", width: "100%", justifyContent: "center", marginTop: 14 }}>
        <h3 style={{ color: "#8D8D8D", fontWeight: 600, whiteSpace: "nowrap" }}>{subTitle}</h3>
      </div>
    </div>
  );
};

export default StatisticsLayout;
