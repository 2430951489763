import React, { FC, useMemo } from "react";
import { Cell, Pie, PieChart } from "recharts";
import { ChartQuestionAnswersResponseType } from "../../types/chartQuestionAnswersInfoType";
import { CalculateAverageRating } from "../icons";

const RADIAN = Math.PI / 180;
const cx = 150;
const cy = 150;
const iR = 50;
const oR = 100;
const COLORS = ["red", "yellow", "green"];

const getAverageScore = (data: ChartQuestionAnswersResponseType) => {
  let totalScore = 0;
  let count = 0;

  data.forEach((question) => {
    question.answers.forEach((answer) => {
      const score = parseFloat(answer.answerMessage);
      if (!isNaN(score)) {
        totalScore += score * answer.answerCount;
        count += answer.answerCount;
      }
    });
  });

  return count > 0 ? totalScore / count : 0;
};

const getGaugeValue = (score: number) => {
  return (score / 10) * 100;
};

const needle = (value: number, cx: number, cy: number, iR: number, oR: number, color: string | undefined) => {
  const ang = 180.0 * (1 - value / 100);
  const length = (iR + 2 * oR) / 3;
  const sin = Math.sin(-RADIAN * ang);
  const cos = Math.cos(-RADIAN * ang);
  const r = 5;
  const x0 = cx;
  const y0 = cy;
  const xba = x0 + r * sin;
  const yba = y0 - r * cos;
  const xbb = x0 - r * sin;
  const ybb = y0 + r * cos;
  const xp = x0 + length * cos;
  const yp = y0 + length * sin;

  return [
    <circle key="circle" cx={x0} cy={y0} r={r} fill={color} stroke="none" />,
    <path key="path" d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`} stroke="none" fill={color} />,
  ];
};

const PieChartWithNeedle: FC<{ data: ChartQuestionAnswersResponseType }> = ({ data }) => {
  const averageScore = useMemo(() => getAverageScore(data), [data]);
  const gaugeValue = useMemo(() => getGaugeValue(averageScore), [averageScore]);

  const gaugeData = [
    { name: "Low", value: 33, color: COLORS[0] },
    { name: "Medium", value: 34, color: COLORS[1] },
    { name: "High", value: 33, color: COLORS[2] },
  ];

  return (
    <div style={{ display: "flex", alignItems: "center", margin: "auto", gap: "300px" }}>
      <PieChart width={300} height={250}>
        <Pie
          dataKey="value"
          startAngle={180}
          endAngle={0}
          data={gaugeData}
          cx={cx}
          cy={cy}
          innerRadius={iR}
          outerRadius={oR}
          stroke="none"
        >
          {gaugeData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
        {needle(gaugeValue, cx, cy, iR, oR, "black")}
        <text x={cx - 75} y={cy + 40} textAnchor="middle" fontSize={15} fill="black">
          Плохо
        </text>
        <text x={cx + 80} y={cy + 40} textAnchor="middle" fontSize={15} fill="black">
          Хорошо
        </text>
      </PieChart>

      <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <CalculateAverageRating />
        <span style={{ color: "#8D8D8D", fontSize: 18, fontWeight: 500 }}>
          Средняя оценка: {averageScore.toFixed(1)}
        </span>
      </div>
    </div>
  );
};

export default PieChartWithNeedle;
