export const HandUpIcon = () => {
  return (
    <svg
      style={{ width: "inherit", height: "inherit" }}
      xmlns="http://www.w3.org/2000/svg"
      height="1em"
      viewBox="0 0 448 512"
    >
      <path d="M160 64c0-8.8 7.2-16 16-16s16 7.2 16 16V200c0 10.3 6.6 19.5 16.4 22.8s20.6-.1 26.8-8.3c3-3.9 7.6-6.4 12.8-6.4c8.8 0 16 7.2 16 16c0 10.3 6.6 19.5 16.4 22.8s20.6-.1 26.8-8.3c3-3.9 7.6-6.4 12.8-6.4c7.8 0 14.3 5.6 15.7 13c1.6 8.2 7.3 15.1 15.1 18s16.7 1.6 23.3-3.6c2.7-2.1 6.1-3.4 9.9-3.4c8.8 0 16 7.2 16 16l0 16V392c0 39.8-32.2 72-72 72H272 212.3h-.9c-37.4 0-72.4-18.7-93.2-49.9L50.7 312.9c-4.9-7.4-2.9-17.3 4.4-22.2s17.3-2.9 22.2 4.4L116 353.2c5.9 8.8 16.8 12.7 26.9 9.7s17-12.4 17-23V320 64zM176 0c-35.3 0-64 28.7-64 64V261.7C91.2 238 55.5 232.8 28.5 250.7C-.9 270.4-8.9 310.1 10.8 339.5L78.3 440.8c29.7 44.5 79.6 71.2 133.1 71.2h.9H272h56c66.3 0 120-53.7 120-120V288l0-16c0-35.3-28.7-64-64-64c-4.5 0-8.8 .5-13 1.3c-11.7-15.4-30.2-25.3-51-25.3c-6.9 0-13.5 1.1-19.7 3.1C288.7 170.7 269.6 160 248 160c-2.7 0-5.4 .2-8 .5V64c0-35.3-28.7-64-64-64zm48 304c0-8.8-7.2-16-16-16s-16 7.2-16 16v96c0 8.8 7.2 16 16 16s16-7.2 16-16V304zm48-16c-8.8 0-16 7.2-16 16v96c0 8.8 7.2 16 16 16s16-7.2 16-16V304c0-8.8-7.2-16-16-16zm80 16c0-8.8-7.2-16-16-16s-16 7.2-16 16v96c0 8.8 7.2 16 16 16s16-7.2 16-16V304z" />
    </svg>
  );
};
export const PlayIcon = () => {
  return (
    <svg
      style={{ width: "inherit", height: "inherit" }}
      xmlns="http://www.w3.org/2000/svg"
      height="1em"
      viewBox="0 0 384 512"
    >
      <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z" />
    </svg>
  );
};
export const ClockIcon = () => {
  return (
    <svg
      style={{ width: "inherit", height: "inherit" }}
      xmlns="http://www.w3.org/2000/svg"
      height="1em"
      viewBox="0 0 512 512"
    >
      <path d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z" />
    </svg>
  );
};
export const CalendarIcon = () => {
  return (
    <svg
      style={{ width: "inherit", height: "inherit" }}
      xmlns="http://www.w3.org/2000/svg"
      height="1em"
      viewBox="0 0 448 512"
    >
      <path d="M128 0c13.3 0 24 10.7 24 24V64H296V24c0-13.3 10.7-24 24-24s24 10.7 24 24V64h40c35.3 0 64 28.7 64 64v16 48V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V192 144 128C0 92.7 28.7 64 64 64h40V24c0-13.3 10.7-24 24-24zM400 192H48V448c0 8.8 7.2 16 16 16H384c8.8 0 16-7.2 16-16V192zM329 297L217 409c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47 95-95c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
    </svg>
  );
};
export const PercentageIcon = () => {
  return (
    <svg
      style={{ width: "inherit", height: "inherit" }}
      xmlns="http://www.w3.org/2000/svg"
      height="1em"
      viewBox="0 0 384 512"
    >
      <path d="M374.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-320 320c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l320-320zM128 128A64 64 0 1 0 0 128a64 64 0 1 0 128 0zM384 384a64 64 0 1 0 -128 0 64 64 0 1 0 128 0z" />
    </svg>
  );
};
export const QuestionIcon = () => {
  return (
    <svg
      style={{ width: "inherit", height: "inherit" }}
      xmlns="http://www.w3.org/2000/svg"
      height="1em"
      viewBox="64 0 384 512"
    >
      <path d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm169.8-90.7c7.9-22.3 29.1-37.3 52.8-37.3h58.3c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L280 264.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24V250.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1H222.6c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z" />
    </svg>
  );
};

export const CopyLink = () => {
  return (
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.1792 27.6065L15.8222 29.9635C12.5678 33.2179 7.29146 33.2179 4.03709 29.9635C0.782721 26.7091 0.782721 21.4327 4.03709 18.1784L6.39411 15.8214M27.6073 18.1784L29.9643 15.8213C33.2187 12.567 33.2187 7.29061 29.9643 4.03624C26.71 0.781866 21.4336 0.781867 18.1792 4.03624L15.8222 6.39326M11.1674 22.8332L22.834 11.1665"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const QrCode = () => {
  return (
    <svg width="33" height="34" viewBox="0 0 33 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.66667 17.9999H16V26.3333M1.01667 17.9999H1M9.35 26.3333H9.33333M16.0167 32.9999H16M31.0167 17.9999H31M1 26.3333H3.5M21.8333 17.9999H25.1667M1 32.9999H9.33333M16 1.33325V11.3333M25.3333 32.9999H28.3333C29.2668 32.9999 29.7335 32.9999 30.09 32.8183C30.4036 32.6585 30.6586 32.4035 30.8183 32.0899C31 31.7334 31 31.2667 31 30.3333V27.3333C31 26.3998 31 25.9331 30.8183 25.5766C30.6586 25.263 30.4036 25.008 30.09 24.8482C29.7335 24.6666 29.2668 24.6666 28.3333 24.6666H25.3333C24.3999 24.6666 23.9332 24.6666 23.5767 24.8482C23.2631 25.008 23.0081 25.263 22.8483 25.5766C22.6667 25.9331 22.6667 26.3998 22.6667 27.3333V30.3333C22.6667 31.2667 22.6667 31.7334 22.8483 32.0899C23.0081 32.4035 23.2631 32.6585 23.5767 32.8183C23.9332 32.9999 24.3999 32.9999 25.3333 32.9999ZM25.3333 11.3333H28.3333C29.2668 11.3333 29.7335 11.3333 30.09 11.1516C30.4036 10.9918 30.6586 10.7368 30.8183 10.4232C31 10.0667 31 9.60001 31 8.66659V5.66659C31 4.73316 31 4.26645 30.8183 3.90993C30.6586 3.59633 30.4036 3.34136 30.09 3.18157C29.7335 2.99992 29.2668 2.99992 28.3333 2.99992H25.3333C24.3999 2.99992 23.9332 2.99992 23.5767 3.18157C23.2631 3.34136 23.0081 3.59633 22.8483 3.90993C22.6667 4.26645 22.6667 4.73316 22.6667 5.66659V8.66659C22.6667 9.60001 22.6667 10.0667 22.8483 10.4232C23.0081 10.7368 23.2631 10.9918 23.5767 11.1516C23.9332 11.3333 24.3999 11.3333 25.3333 11.3333ZM3.66667 11.3333H6.66667C7.60009 11.3333 8.0668 11.3333 8.42332 11.1516C8.73692 10.9918 8.99189 10.7368 9.15168 10.4232C9.33333 10.0667 9.33333 9.60001 9.33333 8.66659V5.66659C9.33333 4.73316 9.33333 4.26645 9.15168 3.90993C8.99189 3.59633 8.73692 3.34136 8.42332 3.18157C8.0668 2.99992 7.60009 2.99992 6.66667 2.99992H3.66667C2.73325 2.99992 2.26654 2.99992 1.91002 3.18157C1.59641 3.34136 1.34144 3.59633 1.18166 3.90993C1 4.26645 1 4.73316 1 5.66659V8.66659C1 9.60001 1 10.0667 1.18166 10.4232C1.34144 10.7368 1.59641 10.9918 1.91002 11.1516C2.26654 11.3333 2.73325 11.3333 3.66667 11.3333Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const IFrame = () => {
  return (
    <svg width="36" height="32" viewBox="0 0 36 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M34.6666 11H1.33325M21.3333 25.1667L25.4999 21L21.3333 16.8333M14.6666 16.8333L10.4999 21L14.6666 25.1667M1.33325 9L1.33325 23C1.33325 25.8003 1.33325 27.2004 1.87822 28.2699C2.35759 29.2108 3.12249 29.9757 4.0633 30.455C5.13286 31 6.53299 31 9.33325 31H26.6666C29.4669 31 30.867 31 31.9365 30.455C32.8774 29.9757 33.6423 29.2108 34.1216 28.27C34.6666 27.2004 34.6666 25.8003 34.6666 23V9C34.6666 6.19974 34.6666 4.79961 34.1216 3.73005C33.6423 2.78924 32.8774 2.02434 31.9365 1.54497C30.867 1 29.4669 1 26.6666 1L9.33325 1C6.53299 1 5.13286 1 4.0633 1.54497C3.12249 2.02433 2.35759 2.78924 1.87822 3.73005C1.33325 4.79961 1.33325 6.19974 1.33325 9Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const SocialMedia = () => {
  return (
    <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M29.8958 13.6667C32.2545 13.6667 34.1667 11.7545 34.1667 9.39583C34.1667 7.03712 32.2545 5.125 29.8958 5.125C27.5371 5.125 25.625 7.03712 25.625 9.39583C25.625 11.7545 27.5371 13.6667 29.8958 13.6667Z"
        stroke="#181236"
        strokeWidth="1.5"
      />
      <path
        d="M11.1042 24.7708C13.4629 24.7708 15.375 22.8587 15.375 20.5C15.375 18.1413 13.4629 16.2291 11.1042 16.2291C8.74551 16.2291 6.83337 18.1413 6.83337 20.5C6.83337 22.8587 8.74551 24.7708 11.1042 24.7708Z"
        stroke="#181236"
        strokeWidth="1.5"
      />
      <path d="M25.6254 11.5948L14.8107 18.1471" stroke="#181236" strokeWidth="1.5" strokeLinecap="square" />
      <path d="M14.8099 22.6899L26.2049 29.4233" stroke="#181236" strokeWidth="1.5" strokeLinecap="square" />
      <path
        d="M29.8958 27.3334C32.2545 27.3334 34.1667 29.2455 34.1667 31.6042C34.1667 33.9629 32.2545 35.875 29.8958 35.875C27.5371 35.875 25.625 33.9629 25.625 31.6042C25.625 29.2455 27.5371 27.3334 29.8958 27.3334Z"
        stroke="#181236"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export const Email = () => {
  return (
    <svg width="36" height="30" viewBox="0 0 36 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M33.8332 25.0001L22.7618 15.0001M13.238 15.0001L2.16664 25.0001M1.33325 6.66675L14.9415 16.1925C16.0434 16.9639 16.5944 17.3495 17.1937 17.4989C17.7231 17.6309 18.2768 17.6309 18.8061 17.4989C19.4055 17.3495 19.9564 16.9639 21.0584 16.1925L34.6666 6.66675M9.33325 28.3334H26.6666C29.4668 28.3334 30.867 28.3334 31.9365 27.7884C32.8773 27.3091 33.6423 26.5442 34.1216 25.6034C34.6666 24.5338 34.6666 23.1337 34.6666 20.3334V9.66675C34.6666 6.86649 34.6666 5.46635 34.1216 4.3968C33.6423 3.45599 32.8773 2.69108 31.9365 2.21172C30.867 1.66675 29.4668 1.66675 26.6666 1.66675H9.33325C6.53299 1.66675 5.13286 1.66675 4.0633 2.21172C3.12249 2.69108 2.35759 3.45599 1.87822 4.3968C1.33325 5.46635 1.33325 6.86649 1.33325 9.66675V20.3334C1.33325 23.1337 1.33325 24.5338 1.87822 25.6034C2.35759 26.5442 3.12249 27.3091 4.0633 27.7884C5.13286 28.3334 6.53299 28.3334 9.33325 28.3334Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const SMS = () => {
  return (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.66667 10.1667H16M7.66667 16H21M7.66667 26V29.8925C7.66667 30.7806 7.66667 31.2246 7.84871 31.4527C8.00704 31.651 8.24712 31.7664 8.5009 31.7661C8.79271 31.7658 9.13945 31.4884 9.83293 30.9337L13.8087 27.7531C14.6209 27.1033 15.0269 26.7784 15.4791 26.5474C15.8803 26.3425 16.3074 26.1927 16.7487 26.1021C17.2461 26 17.7662 26 18.8062 26H23C25.8003 26 27.2004 26 28.27 25.455C29.2108 24.9757 29.9757 24.2108 30.455 23.27C31 22.2004 31 20.8003 31 18V9C31 6.19974 31 4.79961 30.455 3.73005C29.9757 2.78924 29.2108 2.02433 28.27 1.54497C27.2004 1 25.8003 1 23 1H9C6.19974 1 4.79961 1 3.73005 1.54497C2.78924 2.02433 2.02433 2.78924 1.54497 3.73005C1 4.79961 1 6.19974 1 9V19.3333C1 20.8833 1 21.6583 1.17037 22.2941C1.63271 24.0196 2.98044 25.3673 4.7059 25.8296C5.34174 26 6.11671 26 7.66667 26Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const CalculateAverageRating = () => {
  return (
    <svg width="100" height="100" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <polygon
        points="12,2 15,9 22,9 16.5,14 18,21 12,17 6,21 7.5,14 2,9 9,9"
        fill="gold"
        stroke="black"
        stroke-width="1"
      />
      <text
        x="12"
        y="13"
        font-size="6"
        font-weight="bold"
        fill="black"
        text-anchor="middle"
        alignment-baseline="middle"
      ></text>
    </svg>
  );
};
